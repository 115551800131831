
export class GoToSupportForm {
    public static readonly type = '[Support] Go to support form';
    constructor() {
    }
}

export class SendRequest {
    public static readonly type = '[Support] Send request';
    constructor() {
    }
}

export class InitiateDeletionAccountRequest {
    public static readonly type = '[Support] Initiate Deletion account request';
    constructor() {
    }
}

export class ConfirmDeletionAccountRequest {
    public static readonly type = '[Support] Confirm Deletion account request';
    constructor() {
    }
}

export class CancelDeletionAccountRequest {
    public static readonly type = '[Support] Cancel Deletion account request';
    constructor() {
    }
}

export class InitForm {
    public static readonly type = '[Support] Init support form';
    constructor() {
    }
}
